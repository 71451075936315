html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  height: 100%;
  font-size: 14px;
}
@media only screen and (min-width: 1600px) {
  html,
  body {
    font-size: 16px;
  }
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #dddddd;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 4px;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.MuiButton-contained {
  box-shadow: none !important;
}

/* @font-face {
  font-family: "Metropolis";
  src: url("/fonts/metropolis/Metropolis-ExtraLight.otf") format("woff2");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  src: url("/fonts/metropolis/Metropolis-Thin.otf") format("woff2");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
} */

@font-face {
  font-family: "Metropolis";
  src: url("/fonts/metropolis/Metropolis-Regular.otf") format("woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  src: url("/fonts/metropolis/Metropolis-Medium.otf") format("woff2");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("/fonts/metropolis/Metropolis-SemiBold.otf") format("woff2");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  src: url("/fonts/metropolis/Metropolis-Bold.otf") format("woff2");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
/* @font-face {
  font-family: "Metropolis";
  src: url("/fonts/metropolis/Metropolis-ExtraBold.otf") format("woff2");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
} */

/* @font-face {
  font-family: "Metropolis";
  src: url("/fonts/metropolis/Metropolis-ExtraLightItalic.otf") format("woff2");
  font-style: italic;
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  src: url("/fonts/metropolis/Metropolis-ThinItalic.otf") format("woff2");
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("/fonts/metropolis/Metropolis-RegularItalic.otf") format("woff2");
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  src: url("/fonts/metropolis/Metropolis-MediumItalic.otf") format("woff2");
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("/fonts/metropolis/Metropolis-SemiBoldItalic.otf") format("woff2");
  font-style:italic;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  src: url("/fonts/metropolis/Metropolis-BoldItalic.otf") format("woff2");
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  src: url("/fonts/metropolis/Metropolis-ExtraBoldItalic.otf") format("woff2");
  font-style: italic;
  font-weight: 800;
  font-display: swap;
} */

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes glow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.SnackbarContainer-root {
  z-index: 1502 !important;
}
.MuiModal-root {
  z-index: 1500 !important;
}

/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: "←";
}
[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: "→";
}
[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: 30px;

  display: block;

  width: 60%;
  transform: translateX(100px);
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 15px;
  height: 20px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 15px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 15px;
  height: 20px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: #2962ef;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #2962ef;
}
